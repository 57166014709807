import * as React from "react";
import { heroLandingPageTypes } from "../../assets/Types/AcfGroupTypes/CtaLandingPage.type";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import styles from "./CtaLandingPageBody.module.scss";

const CtaLandingPageBody: React.FC<{ heroLandingPage: heroLandingPageTypes }> =
  ({
    heroLandingPage: {
      lpHeroCtaCopy,
      lpHeroCtaLink,
      lpHeroHeader,
      lpHeroSubcopy,
      lpHeroImage,
    },
  }) => {
    const image = getImage(lpHeroImage?.localFile);
    return (
      <section className={`full-width`}>
        <div className={`${styles.PageBodyContainer} grey--faint-bg`}>
          <div>
            <GatsbyImage
              image={image}
              alt={lpHeroHeader || ""}
              imgStyle={{
                maxHeight:
                  lpHeroImage?.localFile?.childImageSharp?.gatsbyImageData
                    .height,
              }}
            />
            <h2 data-testid="h2:CtaLandingPageBody">{lpHeroHeader}</h2>
            <p>{lpHeroSubcopy} </p>
            <a data-testid="a:CtaLandingPageBody" href={lpHeroCtaLink}>
              {lpHeroCtaCopy}{" "}
            </a>
          </div>
        </div>
      </section>
    );
  };

export default CtaLandingPageBody;
