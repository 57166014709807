import * as React from "react";
import { LandingPagefreeResourcesTypes } from "../../assets/Types/AcfGroupTypes/CtaLandingPage.type";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import styles from "./FreeResourcesCard.module.scss";

const CtaLandingPageFreeResources: React.FC<{
  card: LandingPagefreeResourcesTypes;
}> = ({ card: { linkCopy, linkAnchor, fieldGroupName, image } }) => {
  const picture = getImage(image?.localFile);

  return (
    <div className={styles.gridItem}>
      <div className={styles.gridItemTop}>
        <GatsbyImage image={picture} alt={linkCopy || ""} />
      </div>
      <div className={styles.gridItemBottom}>
        <a href={linkAnchor}>{linkCopy}</a>
      </div>
    </div>
  );
};

export default CtaLandingPageFreeResources;
