import * as React from "react";
import { graphql, PageProps } from "gatsby";
import CtaLandingPageBody from "../../components/CtaLandingPageBody/CtaLandingPageBody";
import CtaLandingPageFreeResources from "../../components/CtaLandingPageFreeResources/CtaLandingPageFreeResources";
import { CtaLandingTypes } from "../../assets/Types/AcfGroupTypes/CtaLandingPage.type";
import Landing from "../../layouts/landing";
import SEO from "gatsby-plugin-wpgraphql-seo";
import { deIndexSeo } from "../../utils/deIndexSeo";

const CTALandingPage: React.FC<PageProps<CtaLandingTypes>> = ({ data }) => {
  const { wpPage } = data;
  const {
    template: { heroLandingPageP, resourcesLandingPageP },
  } = wpPage;
  return (
    <Landing hasFreeTrialBtn={false} lpLayout="lp_with_video">
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <CtaLandingPageBody heroLandingPage={heroLandingPageP} />
      <CtaLandingPageFreeResources
        resourcesLandingPageP={resourcesLandingPageP}
      />
    </Landing>
  );
};

export const query = graphql`
  query CTALandingPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      template {
        ... on WpTemplate_CTALandingPage {
          templateName
          heroLandingPageP {
            lpHeroCtaCopy
            lpHeroCtaLink
            lpHeroHeader
            lpHeroSubcopy
            lpHeroImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 480
                    placeholder: BLURRED
                    formats: [WEBP, JPG, AUTO]
                  )
                }
              }
            }
          }
          resourcesLandingPageP {
            resourceSectionTitle
            freeResourcesRepeater {
              linkCopy
              linkAnchor
              fieldGroupName
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 512
                      placeholder: BLURRED
                      formats: [WEBP, JPG, AUTO]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CTALandingPage;
