import * as React from "react";
import styles from "./CtaLandingPageFreeResources.module.scss";

import {
  resourcesLandingPageTypes,
  LandingPagefreeResourcesTypes,
} from "../../assets/Types/AcfGroupTypes/CtaLandingPage.type";
import FreeResourcesCard from "./FreeResourcesCard/FreeResourcesCard";

const CtaLandingPageFreeResources: React.FC<{
  resourcesLandingPageP: resourcesLandingPageTypes;
}> = ({
  resourcesLandingPageP: { resourceSectionTitle, freeResourcesRepeater },
}) => {
  const freeResourcesCard = freeResourcesRepeater.map(
    (card: LandingPagefreeResourcesTypes, index: number) => {
      return <FreeResourcesCard card={card} key={index} />;
    }
  );
  return (
    <section className={`full-width ${styles.freeResourcesCotainer}`}>
      <p data-testid="p:CtaLandingPageFreeResources">{resourceSectionTitle} </p>
      <div className={styles.cardsGridContainer}>{freeResourcesCard}</div>
    </section>
  );
};

export default CtaLandingPageFreeResources;
